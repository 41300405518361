import React from "react"
import "../assets/styles/components/videocard.scss"

const VideoCard = (props: { thumbnail: any; videotitle: any; videodes: any; meta: any }) => {
  const { thumbnail, videotitle, videodes, meta } = props

  return (
    <div className="video--card--wrapper">
      <div className="thumbnail--wrapper">
        <img src={thumbnail} alt="" />
      </div>
      <div className="video--text--wrapper">
        <div className="video--text--inner">
          <p className="video--head--text--sub">
            <span className="video--head--text--bold">{videotitle}</span>
            {videodes}
          </p>
        </div>
        <div className="meta">
          <p>{meta}</p>
        </div>
      </div>
    </div>
  )
}

export default VideoCard